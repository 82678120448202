import { Component, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
// import { MetaService } from './core/services/meta.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  route: string[] = ['/contact-us', '/access-demo', '/coming-soon', '/not-found'];
  // private destroy$ = new Subject<void>();
  constructor(
    private readonly elementRef: ElementRef,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      if (this.route.includes(window.location.pathname)) {
        document.body.classList.add('hiden-footer-subscribe');
      } else {
        document.body.classList.remove('hiden-footer-subscribe');
      }
    }
  }
  showMenu() {
    const element = (this.elementRef.nativeElement as HTMLElement).querySelector('.layout-wrapper');
    if (element?.classList.contains('show-menu')) {
      element?.classList.remove('show-menu');
    } else {
      element?.classList.add('show-menu');
    }
  }
  onContactUs() {
    this.router.navigate(['contact-us']);
  }

  // ngOnInit(): void {
  //   this.subscribeToRouteChanges();
  // }
  // subscribeToRouteChanges() {
  //   this.router.events
  //     .pipe(
  //       filter((event) => event instanceof NavigationEnd),
  //       map(() => this.activatedroute),
  //       map((route) => {
  //         while (route.firstChild) {
  //           route = route.firstChild;
  //         }
  //         return route;
  //       }),
  //       filter((route) => route.outlet === 'primary'),
  //       mergeMap((route) => route.data),
  //       takeUntil(this.destroy$)
  //     )
  //     .subscribe((data) => {
  //       this.metaService.setMeta(data['meta']);
  //     });
  // }

  // ngOnDestroy() {
  //   this.destroy$.next();
  //   this.destroy$.complete();
  // }
}
