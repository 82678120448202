<div class="footer">
  <div class="container">
    <section class="section-subscribe">
      <app-subscribe-newsletter></app-subscribe-newsletter>
    </section>
  </div>
  <section class="section-footer">
    <div class="container">
      <div class="footer-menu">
        <div class="logo-wrapper" (contextmenu)="$event.stopPropagation(); $event.preventDefault()">
          <a href="/"> <img src="/assets/images/logo.svg" alt="logo" loading="lazy" /></a>
        </div>
        <div class="footer-menu-list">
          <a href="#features" class="desktop-menu__item">Features</a>
          <a href="/supported-exchanges" class="desktop-menu__item">Exchanges</a>
          <a href="https://docs.axon.trade" target="_blank" class="desktop-menu__item">Docs</a>
          <a href="/blog" class="desktop-menu__item">Blog</a>
          <a href="/contact-us" class="desktop-menu__item">Contact Us</a>
        </div>
      </div>
      <div class="footer-divider"></div>
      <div class="footer__socials__wrap">
        <div class="footer__socials">
          <ng-container *ngFor="let item of socialList">
            <a [href]="item.href" class="social__link" target="_blank">
              <img [src]="item.iconUrl" [alt]="item.alt" loading="lazy" />
            </a>
          </ng-container>
        </div>
        <p class="copyright">© 2024 Axon Trade. All rights reserved.</p>
        <div class="footer-menu footer-menu--privacy">
          <a
            href="https://app.termly.io/policy-viewer/policy.html?policyUUID=df508abd-d1a5-4c94-baa9-200ba32f6c0f"
            target="_blank"
            class="desktop-menu__item"
            >Privacy Policy</a
          >
        </div>
      </div>
    </div>
  </section>
</div>
