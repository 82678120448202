<div class="wrapper layout-wrapper">
  <div class="mobile-menu-wrap">
    <ng-container *ngTemplateOutlet="menuContent"></ng-container>
  </div>
  <div class="layout-content">
    <div class="content-menu">
      <div class="container-menu">
        <div class="mobile-menu" (click)="showMenu()">
          <img src="assets/icons/menu.svg" alt="menu.svg" loading="lazy" />
        </div>
        <ng-container *ngTemplateOutlet="menuContent"></ng-container>
      </div>
    </div>
    <div class="content scroll-content">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>

  <div class="layout-mask" (click)="showMenu()"></div>
</div>

<ng-template #menuContent>
  <div class="logo-wrapper" (contextmenu)="$event.stopPropagation(); $event.preventDefault()">
    <a href="/"> <img src="/assets/images/logo.svg" alt="axon-trade-logo" loading="lazy" /></a>
  </div>
  <div class="desktop-menu">
    <a href="#features" class="desktop-menu__item">Features</a>
    <a href="/supported-exchanges" class="desktop-menu__item">Exchanges</a>
    <a href="https://docs.axon.trade" target="_blank" class="desktop-menu__item">Docs</a>
    <a href="/blog" class="desktop-menu__item">Blog</a>
    <a class="desktop-menu__button" href="/contact-us"> <button class="btn" type="button">Contact Us</button> </a>
  </div>
</ng-template>
