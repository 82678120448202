import { Component, Inject, LOCALE_ID, OnDestroy } from '@angular/core';
import Botpoison from '@botpoison/browser';
import { FormControl, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subscribe-newsletter',
  templateUrl: './subscribe-newsletter.component.html',
  styleUrl: './subscribe-newsletter.component.scss',
})
export class SubscribeNewsletterComponent implements OnDestroy {
  email: FormControl;

  botpoison = new Botpoison({
    publicKey: 'pk_523b7ff1-e722-4000-b65c-3b9b1ff0ef8f',
  });
  private destroy$ = new Subject<void>();

  constructor(@Inject(LOCALE_ID) private locale: string, private http: HttpClient) {
    this.email = new FormControl(null, Validators.compose([Validators.required, Validators.email]));

    this.email.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (!res) {
        this.email?.markAsPristine();
      }
    });
  }

  async submit() {
    if (this.email.invalid) {
      this.email.setErrors({ errorFromBack: true });
      this.email?.markAsTouched();
      this.email?.markAsDirty();
      return;
    } else {
      const { solution } = await this.botpoison.challenge();
      const data = {
        Message: 'New Axon Trade Newsletter Subscriber',
        Subscriber: this.email.value,
        Timestamp: formatDate(Date.now(), 'yyyy-MM-dd HH:mm:ss', this.locale, 'UTC'),
        _botpoison: solution,
      };
      this.http
        .post('https://submit-form.com/hOeMGV6sX', {
          ...data,
        })
        .subscribe(() => {
          this.email.reset();
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
