<div class="wrap">
  <div class="custom-grid">
    <div>
      <h2 class="title-additional mb-3">Subscribe to our newsletter</h2>
      <p class="description-secondary">
        Join our mailing list and get the latest news, updates, and exclusive offers delivered to your inbox.
      </p>
    </div>
    <div>
      <div class="form-wrap relative mb-3">
        <div class="error-message" *ngIf="email.hasError('errorFromBack')">
          <div class="error-icon-wrap">
            <img src="assets/icons/exclamation-circle.svg" alt="exclamation-circle.svg" loading="lazy" />
          </div>
          Please enter a valid email address
        </div>
        <input
          [formControl]="email"
          class="custom-input margin-group-right"
          [class.invalid]="email.hasError('errorFromBack')"
          type="text"
          placeholder="Enter your email"
          autocomplete="off"
          name="email"
          type="email"
        />
        <button (click)="submit()" type="button" class="btn mobile-full-button">Subscribe</button>
      </div>
      <p class="form-description">We don’t share your email address publicly</p>
    </div>
  </div>
</div>
