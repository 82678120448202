import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  socialList = [
    {
      iconUrl: '/assets/social-icons/linkedin.svg',
      alt: 'linkedin.svg',
      href: 'https://www.linkedin.com/company/axontrade',
    },
    {
      iconUrl: '/assets/social-icons/twitter.svg',
      alt: 'twitter.svg',
      href: 'https://x.com/axontrade',
    },
    {
      iconUrl: '/assets/social-icons/facebook.svg',
      alt: 'facebook.svg',
      href: 'https://www.facebook.com/axontradeoems/',
    },
    {
      iconUrl: '/assets/social-icons/medium.svg',
      alt: 'medium.svg',
      href: 'https://medium.com/axontrade',
    },
    {
      iconUrl: '/assets/social-icons/youtube.svg',
      alt: 'youtube.svg',
      href: 'https://www.youtube.com/@axontrade',
    },
  ];
}
